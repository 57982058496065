import $ from 'jquery';

export default function modalVideo(trigger, target, targetClose) {

	// Get the button that opens the modal
	// var btn = document.getElementById(trigger);

	// Get the modal
	// var modal = document.getElementById(target);

	// Get the <span> element that closes the modal
	// var span = document.getElementById(targetClose);

	// When the user clicks on the button, open the modal
	// btn.onclick = function() {
	// 	modal.style.display = "block";
	// }

	// // When the user clicks on <span> (x), close the modal
	// span.onclick = function() {
	// 	modal.style.display = "none";
	// }

	// $(target).find('#modal__video').attr('src', '');

	var video = $(trigger).data('video');

	$(trigger).click(function(event) {
		$(target).find('#modal__video').attr('src', video);
		$(target).hide().fadeIn();
	});

	$(targetClose).click(function(event) {
		$(target).find('#modal__video').attr('src', '');
		$(target).fadeOut();
	});

	// When the user clicks anywhere outside of the modal, close it
	// window.onclick = function(event) {
	// 	if (event.target == modal) {
	// 		modal.style.display = "none";
	// 	}
	// }
	$(target).click(function(event) {
		$(target).find('#modal__video').attr('src', '');
		$(target).fadeOut();
	});
}
