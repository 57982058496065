import $ from 'jquery';

export default function stickyHeader (headerid) {
	// When the user scrolls the page, execute stickHeader
	window.onscroll = function() {stickHeader()};

	// Get the header
	var header = document.getElementById(headerid);

	// Get the offset position of the navbar
	var sticky = header.offsetTop;

	// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
	function stickHeader() {
		if (window.pageYOffset > sticky) {
			header.classList.add("header--sticky");
		} else {
			header.classList.remove("header--sticky");
		}
	}
}
